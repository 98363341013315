import { StyleSheet, ScrollView } from 'react-native';
import { View } from '../components/Themed';
import { Avatar, Text } from 'react-native-elements';
import { DataTable } from 'react-native-paper';
import { useState, useEffect } from 'react';

// Firebase auth
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  deleteDoc,
  getDocs,
  orderBy,
  Timestamp,
} from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyAHkqD0BSCCkhnTb8PBVyp32DasmpRUV2c",
  authDomain: "felix-1db5a.firebaseapp.com",
  projectId: "felix-1db5a",
  storageBucket: "felix-1db5a.appspot.com",
  messagingSenderId: "194202836126",
  appId: "1:194202836126:web:c8159c2924b4dc23a56f8f",
  measurementId: "G-98DRHQX2M2"
};

initializeApp(firebaseConfig);
const db = getFirestore();

import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime);


const getNameOrEmail = (player) => {
  return player?.displayName ??
    player?.email?.substring(0, player.email.indexOf("@")) ?? '-';
}

const getWinnerName = (game) => {
  if(game.playerOne.email === game.winner) {
    return getNameOrEmail(game.playerOne);
  }
  else if(game.playerTwo.email === game.winner) {
    return getNameOrEmail(game.playerTwo);
  }
  return 'tie';
}

const getChallengeWord = (game, person) => {
  if(game.playerOne.email === person.email) {
    return game.playerOne.challengeWord;
  }
  else if(game.playerTwo.email === person.email) {
    return game.playerTwo.challengeWord;
  }
}

export default function ScoreScreen({ route, navigation }) {
  const [match, setMatch] = useState(route.params.match);
  const [games, setGames] = useState([]);
  

  const fetchData = async (newMatch) => {
    newMatch.player.wins = {
      month:0,
      week:0,
      day:0,
    };
    newMatch.opponent.wins = {
      month:0,
      week:0,
      day:0,
    }
    const tsMonthStart = Timestamp.fromDate(dayjs().startOf('month').toDate());
    const querySnapshot = await getDocs(
      query(
        collection(db, `/matches/${match.id}/games`),
        where('closed','>=', tsMonthStart))
      );
    querySnapshot.forEach((doc) => {
      if(dayjs.unix(doc.data().closed.seconds).isAfter(dayjs().startOf('month'))) {
        if(doc.data().winner === newMatch.player.email)
          newMatch.player.wins.month++;
        if(doc.data().winner === newMatch.opponent.email)
          newMatch.opponent.wins.month++;
      }
      if(dayjs.unix(doc.data().closed.seconds).isAfter(dayjs().startOf('week'))) {
        if(doc.data().winner === newMatch.player.email)
          newMatch.player.wins.week++;
        if(doc.data().winner === newMatch.opponent.email)
          newMatch.opponent.wins.week++;
      }
      if(dayjs.unix(doc.data().closed.seconds).isAfter(dayjs().startOf('day'))) {
        if(doc.data().winner === newMatch.player.email)
          newMatch.player.wins.day++;
        if(doc.data().winner === newMatch.opponent.email)
          newMatch.opponent.wins.day++;
      }
    });
    setMatch(newMatch);
    setGames(querySnapshot.docs.map(doc=>({...doc.data(), id: doc.id})));
  }

  useEffect(() => {
    console.log('received match id:', match.id);
    console.log('path:', `/matches/${match.id}`);

    const matchRef = doc(db, `/matches/${match.id}`);
    const unsubscribe = onSnapshot(matchRef, (doc) => {
      if(!doc.data()) {
        return;
      }
      let player, opponent;

      if(doc.data().playerDetails?.[0].email == getAuth().currentUser.email) {
        player = {...doc.data().playerDetails[0]};
        opponent = {...doc.data().playerDetails[1]};
      }
      else {
        player = {...doc.data().playerDetails[1]};
        opponent = {...doc.data().playerDetails[0]};
      }

      const newMatch = {
        ...doc.data(),
        id: doc.id,
        player: player,
        opponent: opponent,
      };
      fetchData(newMatch);
    });
    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe()
  }, []);

  if(!match || !match.id)
    return null;

    return (
      <ScrollView>
        <DataTable>
          <DataTable.Header>
            <DataTable.Title>Period</DataTable.Title>
            <DataTable.Title numeric style={{justifyContent:'center'}}>{getNameOrEmail(match.player)}</DataTable.Title>
            <DataTable.Title numeric style={{justifyContent:'center'}}>{getNameOrEmail(match.opponent)}</DataTable.Title>
          </DataTable.Header>

          <DataTable.Row>
            <DataTable.Cell>Today</DataTable.Cell>
            <DataTable.Cell
              numeric
              style={{
                justifyContent:'center',
                backgroundColor:
                  match.player?.wins?.day > match.opponent?.wins?.day ? 'green'
                  : null
              }}
            >
              {match.player?.wins?.day}
            </DataTable.Cell>
            <DataTable.Cell
              numeric
              style={{
                justifyContent:'center',
                backgroundColor:
                  match.player?.wins?.day < match.opponent?.wins?.day ? 'green'
                  : null
              }}
            >
              {match.opponent?.wins?.day}
            </DataTable.Cell>
          </DataTable.Row>

          <DataTable.Row>
            <DataTable.Cell>This week</DataTable.Cell>
            <DataTable.Cell
              numeric
              style={{
                justifyContent:'center',
                backgroundColor:
                  match.player?.wins?.week > match.opponent?.wins?.week ? 'green'
                  : null
              }}
            >
              {match.player?.wins?.week}
            </DataTable.Cell>
            <DataTable.Cell
              numeric
              style={{
                justifyContent:'center',
                backgroundColor:
                match.player?.wins?.week < match.opponent?.wins?.week ? 'green'
                : null
              }}
            >
              {match.opponent?.wins?.week}
            </DataTable.Cell>
          </DataTable.Row>

          <DataTable.Row>
            <DataTable.Cell>This month</DataTable.Cell>
            <DataTable.Cell
              numeric
              style={{
                justifyContent:'center',
                backgroundColor:
                match.player?.wins?.month > match.opponent?.wins?.month ? 'green'
                : null
              }}
            >
              {match.player?.wins?.month}
            </DataTable.Cell>
            <DataTable.Cell
              numeric
              style={{
                justifyContent:'center',
                backgroundColor:
                match.player?.wins?.month < match.opponent?.wins?.month ? 'green'
                : null
              }}
            >
              {match.opponent?.wins?.month}
            </DataTable.Cell>
          </DataTable.Row>
        </DataTable>
        <DataTable>
          <DataTable.Header>
            <DataTable.Title>Date</DataTable.Title>
            <DataTable.Title numeric>Winner</DataTable.Title>
            <DataTable.Title numeric>{getNameOrEmail(match.player)}</DataTable.Title>
            <DataTable.Title numeric>{getNameOrEmail(match.opponent)}</DataTable.Title>
          </DataTable.Header>
        </DataTable>
        {games.reverse().map((game,index)=>(
          <DataTable.Row
            key={index}
            onPress={() => navigation.replace('Game',
                { match,
                  game
                })
            }
          >
            <DataTable.Cell>{dayjs.unix(game.closed.seconds).fromNow()}</DataTable.Cell>
            <DataTable.Cell numeric>{getWinnerName(game)}</DataTable.Cell>
            <DataTable.Cell numeric>{getChallengeWord(game, match.player)}</DataTable.Cell>
            <DataTable.Cell numeric>{getChallengeWord(game, match.opponent)}</DataTable.Cell>
          </DataTable.Row>
        ))}
      </ScrollView>
    );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
});
