/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName, Pressable, View } from 'react-native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import ScoreScreen from '../screens/ScoreScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import TabOneScreen from '../screens/TabOneScreen';
import AddFriendScreen from '../screens/AddFriendScreen';
import { RootStackParamList } from '../types';
import LinkingConfiguration from './LinkingConfiguration';


import {
  getAuth,
  onAuthStateChanged,
  getRedirectResult,
} from 'firebase/auth';

import {useState} from 'react';

import GameScreen from '../screens/GameScreen';
import SettingsScreen from '../screens/SettingsScreen';
import HelpScreen from '../screens/HelpScreen';
import LoginScreen from '../screens/LoginScreen';

import { Text, Icon } from 'react-native-elements';


export default function Navigation({ routeName, colorScheme }: { colorScheme: ColorSchemeName }) {
  // getAuth().signOut();
  // fetch(`https://people.googleapis.com/v1/people:searchContacts?query=${query}&pageSize=10&readMask=emailAddresses,names`)
  // .then(result=>console.log('result:', result)).catch(error=>console.log('error:',error));
  // console.log('people api result:', result);
  return (
    <NavigationContainer
    linking={LinkingConfiguration}
    theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
    <RootNavigator/>
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();


function RootNavigator({routeName}) {
  const colorScheme = useColorScheme();

  const [user, setUser] = useState(getAuth().currentUser);
  getAuth().onAuthStateChanged(user => setUser(user));

  if(!user) {
    return (
      <Stack.Navigator
        initialRouteName= 'Login'
        screenOptions={({ route, navigation }) => ({
          headerMode:'screen',
          headerTitle:'word/off',
          headerTitleAlign:'center',
        })}
      >
        <Stack.Screen name="Login" component={LoginScreen} options={{ headerLeft: null, title: null}}/>
      </Stack.Navigator>
    )
  }

  return (
    <Stack.Navigator
      initialRouteName= 'Friends'
      screenOptions={({ route, navigation }) => ({
        headerMode:'screen',
        headerTitle:'word/off',
        headerTitleAlign:'center',
        headerLeft: ()=>(
          <Pressable
            onPress={() => navigation.navigate('Friends')}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
            })}>
            <Icon
              type='material-community'
              name='account-group'
              color= {colorScheme==='light' ? Colors.light.text :
                                Colors.dark.text}
              style={{ marginLeft: 10 }}
            />
          </Pressable>),
        headerRight: () => (
          <Pressable
            onPress={() => navigation.navigate('Settings')}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
            })}>
            <Icon
              type='material-community'
              name='cog'
              color= {colorScheme==='light' ? Colors.light.text :
                                Colors.dark.text}
              style={{ marginRight: 10 }}
            />
          </Pressable>
        )
      })}
    >
      <Stack.Screen name="Friends" component={TabOneScreen} options={{ headerLeft: null, title: null}}/>
      <Stack.Screen name="AddFriend" component={AddFriendScreen} options={{ title: null}} />
      <Stack.Screen
        name="Game"
        component={GameScreen}
        options={({ route, navigation }) => ({ headerLeft: ()=>
          (
            <View style={{flexDirection:'row'}}>
              <Pressable
                onPress={() => navigation.navigate('Friends')}
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5 : 1,
                })}>
                <Icon
                  type='material-community'
                  name='account-group'
                  color= {colorScheme==='light' ? Colors.light.text :
                                    Colors.dark.text}
                  style={{ marginLeft: 10 }}
                />
              </Pressable>
              <Pressable
                onPress={() => navigation.navigate('Help')}
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5 : 1,
                })}>
                <Icon
                  type='material-community'
                  name='help-box'
                  color= {colorScheme==='light' ? Colors.light.text :
                                    Colors.dark.text}
                  style={{ marginLeft: 10 }}
                />
              </Pressable>
            </View>
          )
        })}/>
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Group
        screenOptions={({ route, navigation }) => ({
          presentation: 'modal',
          headerMode:'screen',
          headerLeft: null,
          headerRight: () => (
            <Pressable
              onPress={() => navigation.pop()}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <Icon
                type='material-community'
                name='close'
                color= {colorScheme==='light' ? Colors.light.text :
                                  Colors.dark.text}
              />
            </Pressable>
          )
          })}
      >
        <Stack.Screen name="Score" component={ScoreScreen} options={{ title: null}}/>
        <Stack.Screen name="Settings" component={SettingsScreen} options={{ title: null}}/>
        <Stack.Screen name="Help" component={HelpScreen} options={{ title: null}}/>
      </Stack.Group>
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
// const BottomTab = createBottomTabNavigator<RootTabParamList>();
//
// function BottomTabNavigator({route}) {
//   const colorScheme = useColorScheme();
//
//   return (
//     <BottomTab.Navigator
//       initialRouteName="Game"
//       screenOptions={{
//         tabBarActiveTintColor: Colors[colorScheme].tint,
//       }}>
//         <BottomTab.Screen
//           name="Game"
//           component={GameScreen}
//           options={({ navigation }: RootTabScreenProps<'Game'>) => ({
//             title: 'Games',
//             tabBarIcon: ({ color }) => <TabBarIcon name="th" color={color} />,
//             headerRight: () => (
//               <Pressable
//                 onPress={() => navigation.navigate('Modal')}
//                 style={({ pressed }) => ({
//                   opacity: pressed ? 0.5 : 1,
//                 })}>
//                 <FontAwesome
//                   name="info-circle"
//                   size={25}
//                   color={Colors[colorScheme].text}
//                   style={{ marginRight: 15 }}
//                 />
//               </Pressable>
//             ),
//           })}
//         />
//
//       <BottomTab.Screen
//         name="TabTwo"
//         component={TabTwoScreen}
//         options={{
//           title: 'Score',
//           tabBarIcon: ({ color }) => <TabBarIcon name="pencil-square-o" color={color} />,
//         }}
//       />
//     </BottomTab.Navigator>
//   );
// }
//
// /**
//  * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
//  */
// function TabBarIcon(props: {
//   name: React.ComponentProps<typeof FontAwesome>['name'];
//   color: string;
// }) {
//   return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
// }
