import { useState, useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';
import { Alert, ActivityIndicator, AppState, Linking, Platform, StyleSheet, ScrollView } from 'react-native';
import { View } from '../components/Themed';
import { ListItem, Switch, Input } from 'react-native-elements';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import * as Contacts from 'expo-contacts';

// Firebase auth
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, setDoc, onSnapshot} from 'firebase/firestore';
const db = getFirestore();




export default function SettingsScreen({ route, navigation }) {
  const [notifications, setNotifications] = useState(false);
  const [contacts, setContacts] = useState(false);
  const [user, setUser] = useState();
  const [displayNameInput, setDisplayNameInput] = useState();
  const [submittingDisplayName, setSubmittingDisplayName] = useState(false);
  const submitDisplayName = async (displayName) => {
    const userRef = doc(db, 'users', getAuth().currentUser.email);
    const update = { displayName };
    setSubmittingDisplayName(true);
    await setDoc(userRef, update, {merge:true})
      .catch(error=>console.log(error))
      .finally(()=>setSubmittingDisplayName(false));
  }


  const discoverPermissionsAsync = async () => {
    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        setNotifications(false);
      } else {
        setNotifications(true);
      }

      const { status: existingStatusC } = await Contacts.getPermissionsAsync();
      finalStatus = existingStatusC;
      if (existingStatusC !== 'granted') {
        const { status } = await Contacts.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        setContacts(false);
      } else {
        setContacts(true);
      }
    } else {
      console.log('Not on physical device');
      setContacts(false);
    }
  }
  useEffect(() => discoverPermissionsAsync());
  useEffect(() => {
    AppState.addEventListener('change', discoverPermissionsAsync);

    return () => {
      AppState.removeEventListener('change', discoverPermissionsAsync);
    };
  }, []);

  useEffect(() => {
    const userRef = doc(db, `/users/${getAuth().currentUser.email}`);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      // console.log("Current data: ", doc.data());
      if(!doc.data()) {
        return;
      }
      setUser({
        ...doc.data(),
        id: doc.id,
      });
      setDisplayNameInput(doc.data().displayName);
    });
    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe()
  }, []);


    return (
      <ScrollView containerStyle={styles.container}>
        <ListItem key={1}>
          <ListItem.Content>
            <ListItem.Title>Permissions</ListItem.Title>
          </ListItem.Content>
        </ListItem>

        <ListItem key={2}>
          <ListItem.Content>
            <ListItem.Title style={{flex:1}}>    Notifications</ListItem.Title>
            {Platform.OS==='web' && <ListItem.Subtitle>      (iOS/Android only)</ListItem.Subtitle> }
          </ListItem.Content>
          <Switch
            disabled={Platform.OS ==='web'}
            value={notifications}
            onValueChange={()=> {
              Alert.alert(
                'Notifications',
                'Change permissions in your app settings',
                [
                  {
                    text: "Cancel",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel"
                  },
                  { text: "OK",
                    onPress: ()=>Linking.openURL('app-settings:')
                  }
                ]
              )
              }
            }
            color={'green'}
          />
        </ListItem>

        <ListItem key={3} bottomDivider>
          <ListItem.Content>
            <ListItem.Title style={{flex:1}}>    Contacts</ListItem.Title>
            {Platform.OS === 'web' && <ListItem.Subtitle>      (iOS/Android only)</ListItem.Subtitle> }
          </ListItem.Content>
          <Switch
            disabled={Platform.OS ==='web'}
            value={notifications}
            onValueChange={()=> {
              Alert.alert(
                'Contacts',
                'Change permissions in your app settings',
                [
                  {
                    text: "Cancel",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel"
                  },
                  { text: "OK",
                    onPress: ()=>Linking.openURL('app-settings:')
                  }
                ]
              )
              }
            }
            color={'green'}
          />
        </ListItem>

        <ListItem key={4}>
          <ListItem.Title style={{flex:1}}>Display name</ListItem.Title>
          <ListItem.Content style={{flexDirection:'row', alignItems:'center'}}>
            <Input
              autoCapitalize='none'
              autoCorrect= {false}
              textAlign='center'
              placeholder='<enter name>'
              value={displayNameInput}
              maxLength={16}
              onChangeText={(t)=>setDisplayNameInput(t)}
              onSubmitEditing={()=>submitDisplayName(displayNameInput)}
              rightIcon={ (!(displayNameInput) || displayNameInput.length==0) ? null : user?.displayName === displayNameInput ?
                {name:'check'} :
                {name:'send', onPress:()=>displayNameInput.length > 0 && submitDisplayName(displayNameInput),
                }
              }
            />
            {submittingDisplayName && <ActivityIndicator/> }
          </ListItem.Content>
        </ListItem>

        <ListItem key={5} bottomDivider onPress={()=>getAuth().signOut()}>
          <ListItem.Title style={{flex:1}}>Logout</ListItem.Title>
          <ListItem.Subtitle>{ getAuth().currentUser.email }</ListItem.Subtitle>
        </ListItem>

        <ListItem key={6} onPress={()=>Linking.openURL('mailto:admin@wordoff.app')}>
          <ListItem.Title style={{flex:1}}>Feedback/bugs/suggestions</ListItem.Title>
          <ListItem.Subtitle>admin@wordoff.app</ListItem.Subtitle>
        </ListItem>
      </ScrollView>
    )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
