import NetInfo from '@react-native-community/netinfo';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { ActivityIndicator, FlatList, StyleSheet, Platform, Pressable, View, KeyboardAvoidingView} from 'react-native';
import { useState, useEffect, useRef } from 'react';
import useColorScheme from '../hooks/useColorScheme';
import Colors from '../constants/Colors';

import { RootTabScreenProps } from '../types';
import { getAuth } from 'firebase/auth';

import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyAHkqD0BSCCkhnTb8PBVyp32DasmpRUV2c",
  authDomain: "felix-1db5a.firebaseapp.com",
  projectId: "felix-1db5a",
  storageBucket: "felix-1db5a.appspot.com",
  messagingSenderId: "194202836126",
  appId: "1:194202836126:web:c8159c2924b4dc23a56f8f",
  measurementId: "G-98DRHQX2M2"
};

initializeApp(firebaseConfig);

import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  deleteDoc,
  setDoc,
  updateDoc,
  getDocs,
  Timestamp,
  increment,
  serverTimestamp,
} from "firebase/firestore";
const db = getFirestore();


import {
  Text,
  ListItem,
  Avatar,
  Icon,
  Badge,
  ListItemProps,
  Button,
  Switch,
  colors,
  Overlay,
  Input,
} from 'react-native-elements';
import * as StoreReview from 'expo-store-review';
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const newGame = httpsCallable(functions, 'newGame');
const validateWord = httpsCallable(functions, 'validateWord');

const wordList = require('word-list-json');

const runTest = async () => {
  // navigation.push('Game', {
  //         match: { id: 'WvI7hFQWYhCNaQsfN39e' },
  //         game: { id: 'g0nTFJNWL9l0oDKaf8QF' }
  //       });
  //
  // const userRef = doc(db, 'users', getAuth().currentUser.email);
  // let update = {
  //   os: Platform.OS,
  //   updated: Timestamp.fromDate(new Date()),
  //   loginCount: increment(1),
  //   photoUrl: getAuth().currentUser.photoUrl ?? null,
  // };
  // await setDoc(userRef, update, {merge:true}).catch(error=>console.log(error));
}

const deleteMatch = async(match) => {
  // console.log(`requesting delete: matches/${match.id}`, match);
  return deleteDoc(doc(db,"matches", match.id));
}

// const writeExpoPushToken = async (expoPushToken) => {
//   const userRef = doc(db, 'users', getAuth().currentUser.email);
//   let update = {
//     expoPushToken,
//     os: Platform.OS,
//     updated: Timestamp.fromDate(new Date()),
//     loginCount: increment(1),
//     photoUrl: getAuth().currentUser.photoUrl ?? null,
//   };
//   await setDoc(userRef, update, {merge:true}).catch(error=>console.log(error));
// }

const registerForPushNotificationsAsync = async () => {
  const userRef = doc(db, 'users', getAuth().currentUser.email);
  let update = {
    os: Platform.OS,
    updated: serverTimestamp(),
    loginCount: increment(1),
    photoUrl: getAuth().currentUser.photoURL ?? null,
  };

  if (Device.isDevice && Platform.OS!=='web') {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      console.log('Failed to get push token for push notification!');
      // return;
    }
    if (finalStatus === 'granted') {
      const token = (await Notifications.getExpoPushTokenAsync()).data;
      update.expoPushToken = token;
    }
  } else {
    console.log('Not on physical device');
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  await setDoc(userRef, update, {merge:true}).catch(error=>console.log(error));
};


export default function TabOneScreen({ navigation }: RootTabScreenProps<'TabOne'>) {
  // console.log('user:', getAuth().currentUser);
  const colorScheme = useColorScheme();

  // TODO: ActivityIndicator
  const [user, setUser] = useState();
  const [matches, setMatches] = useState([]);
  const [waitingForServer, setWaitingForServer] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  // Notifications
  const [expoPushToken, setExpoPushToken] = useState('');
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();

  const [displayNameInput, setDisplayNameInput] = useState();
  const [submittingDisplayName, setSubmittingDisplayName] = useState(false);
  const submitDisplayName = async (displayName) => {
    const userRef = doc(db, 'users', getAuth().currentUser.email);
    const update = { displayName };
    setSubmittingDisplayName(true);
    await setDoc(userRef, update, {merge:true})
      .catch(error=>console.log(error))
      .finally(()=>setSubmittingDisplayName(false));
  }

  // Listen for user
  useEffect(() => {
    const userRef = doc(db, `/users/${getAuth().currentUser.email}`);
    const requestReviewAsync = async () => {
      if (await StoreReview.hasAction()) {
        StoreReview.requestReview();
      }
    }

    const unsubscribe = onSnapshot(userRef, (doc) => {
      // console.log("Current data: ", doc.data());
      NetInfo.fetch().then(state => {
        // console.log('Connection type', state.type);
        // console.log('Is connected?', state.isConnected);
        if(state.isConnected) {
          if(!doc.data()) {
            // console.log(`there's no doc.data():`, doc.data())
            setShowOverlay(true);
            return;
          }
          setUser({
            ...doc.data(),
            id: doc.id,
          });
          if(doc.data().displayName) {
            setShowOverlay(false);
          }
          else if(!doc.data().displayName && !doc.metadata.fromCache){
            //update display name
            // console.log(`no doc.data().displayName`, doc.data());
            // console.log(`metadata`, doc.metadata);
            setShowOverlay(true);
          }

          // request rating/review
          if(doc.data().loginCount >= 10) {
            requestReviewAsync();
          }
        }
      });
    });
    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe()
  }, []);

  // Delete friends header
  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View style={{flexDirection:'row'}}>
          <Pressable
            onPress={() => {setShowDelete(!showDelete)}}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
            })}>
            <Icon
              type='material-community'
              name='account-minus'
              color= {showDelete ? 'red' :
                (colorScheme==='light' ? Colors.light.text : Colors.dark.text)}
              style={{ marginRight: 10 }}

            />
          </Pressable>
          <Pressable
            onPress={() => navigation.navigate('Settings')}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
            })}>
            <Icon
              type='material-community'
              name='cog'
              color= {colorScheme==='light' ? Colors.light.text :
                                Colors.dark.text}
              style={{ marginRight: 10 }}
            />
          </Pressable>
        </View>
      )
    })
  }, [showDelete]);

  // Push Notifications
  useEffect(() => {
      registerForPushNotificationsAsync();

      // This listener is fired whenever a notification is received while the app is foregrounded
      notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
        setNotification(notification);
      });

      // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
      responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
        //console.log(response);
        if(response.notification.request.content.data.matchId && response.notification.request.content.data.gameId ) {
          navigation.push('Game', {
                  match: { id: response.notification.request.content.data.matchId, player: {}, opponent: {} },
                  game: { id: response.notification.request.content.data.gameId }
                });
        }
      });

      return () => {
        Notifications.removeNotificationSubscription(notificationListener.current);
        Notifications.removeNotificationSubscription(responseListener.current);
      };
    }, []);

  // Listen for matches
  useEffect(() => {
    const q = query(collection(db, "matches"), where("players", "array-contains", getAuth().currentUser.email));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      // const matches = querySnapshot.docs.map(doc => {...doc.data(), key:doc.id});
      const matches = [];
      querySnapshot.forEach(doc => {
              matches.push({
                ...doc.data(),
                player: doc.data().playerDetails.find(p => p.email == getAuth().currentUser.email),
                opponent: doc.data().playerDetails.find(p => p.email != getAuth().currentUser.email),
                id: doc.id,
              });
            });
      //console.log("Matches", JSON.stringify(matches, null, 2));
      setMatches(matches);
      setWaitingForServer(false);
    });
    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe()
  }, []);


  if (false) {
    return <Button title='test' onPress={()=>runTest()}/>;
  }

  return (
    <View>
    <Overlay
      isVisible={showOverlay}
      onBackdropPress={()=>setShowOverlay(false)}
      overlayStyle={{maxHeight:'60%', minWidth:250}}
    >
      <Icon name='close' style={{alignSelf:'flex-end'}} onPress={()=>setShowOverlay(false)}/>
      <Text></Text>
      <Text>How do you want your name displayed to others?</Text>
      <View style={{flexDirection:'row'}}>
      <Input
        autoCapitalize='none'
        autoCorrect= {false}
        autoFocus= {true}
        placeholder='<enter name>'
        maxLength={16}
        value={displayNameInput}
        onChangeText={(t)=>setDisplayNameInput(t)}
        onSubmitEditing={()=>displayNameInput.length > 0 && submitDisplayName(displayNameInput)}
        containerStyle={{flex:1}}
        rightIcon={ (!(displayNameInput) || displayNameInput.length==0) ? null : user?.displayName === displayNameInput ?
          {name:'check'}:
          {name:'send', onPress:()=>displayNameInput.length > 0 && submitDisplayName(displayNameInput),
          }
        }
      />
      </View>
      <Text>(You can always change this later in settings)</Text>
    </Overlay>
    <FlatList
      data={matches}
      ListFooterComponent={waitingForServer && <ActivityIndicator/>}
      ListHeaderComponent=
        <ListItem
          key={0}
          onPress={() => navigation.push('AddFriend')}
          bottomDivider
        >
        <Avatar
            size={64}
            rounded={false}
            title= {'+'}
            containerStyle={styles.avatarContainer}
          />
        <Text h4>Add friend</Text>
        </ListItem>
      renderItem={({ item }) => (
        <ListItem
          key={item.id}
          onPress={() => navigation.push('Game',
              { match: item,
                game: {
                  id: item.player.currentGame
                }
              })
          }
          bottomDivider
          leftContent={null}
          rightContent={
            <Button
              title="Delete"
              icon={{ name: 'delete', color: 'white' }}
              buttonStyle={{ minHeight: '100%', backgroundColor: 'red' }}
              onPress={async ()=> {
                try {
                  setWaitingForServer(true);
                  await deleteMatch(item);
                  setWaitingForServer(false);
                } catch(e) {
                  // console.log('error delete:', e);
                  setWaitingForServer(false);
                }
              }}
          />
        }>
        <Avatar
            size={64}
            rounded={false}
            source= {{uri:item.opponent?.photoUrl ?? 'android_workaround'}}
            title= {item.opponent?.displayName?.charAt(0)?.toUpperCase() ?? item.opponent.email.charAt(0).toUpperCase()}
            containerStyle={styles.avatarContainer}
            placeholderStyle={{backgroundColor:'green'}}
          />
        <ListItem.Content>
          <ListItem.Title>{item.opponent?.displayName ?? item.opponent.email}</ListItem.Title>
          <ListItem.Subtitle>{item.opponent?.displayName ? item.opponent.email : null}</ListItem.Subtitle>
        </ListItem.Content>
          {showDelete && <Button
            icon={{ name: 'delete', color: 'white' }}
            buttonStyle={{  backgroundColor: 'red' }}
            onPress={async ()=> {
              try {
                setWaitingForServer(true);
                await deleteMatch(item);
                setWaitingForServer(false);
              } catch(e) {
                // console.log('error delete:', e);
                setWaitingForServer(false);
              }
            }}
          />}
        </ListItem>
      )}
    />
    </View>

  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  avatarContainer: {
    backgroundColor: 'green',
    borderColor:'grey',
    borderWidth:2,
  },
});
