import { useEffect, useState} from 'react';
import { Button, Image, Pressable, StyleSheet, View, Platform } from 'react-native';
import { Text } from 'react-native-elements';

import * as WebBrowser from 'expo-web-browser';
import { ResponseType } from 'expo-auth-session';
import * as Google from 'expo-auth-session/providers/google';
import * as Facebook from 'expo-auth-session/providers/facebook';
import * as AppleAuthentication from 'expo-apple-authentication';
import * as Crypto from 'expo-crypto';
import splashImage from '../assets/images/splash.png';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  OAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithCredential,
  fetchSignInMethodsForEmail,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAHkqD0BSCCkhnTb8PBVyp32DasmpRUV2c",
  authDomain: "felix-1db5a.firebaseapp.com",
  projectId: "felix-1db5a",
  storageBucket: "felix-1db5a.appspot.com",
  messagingSenderId: "194202836126",
  appId: "1:194202836126:web:c8159c2924b4dc23a56f8f",
  measurementId: "G-98DRHQX2M2"
};

initializeApp(firebaseConfig);

const auth = getAuth();
WebBrowser.maybeCompleteAuthSession();

// Android device workaround
import * as GoogleA from 'expo-google-app-auth';
const config = {
  androidClientId: `194202836126-is8mcur724uat774oldlvlugfhstp7il.apps.googleusercontent.com`,
  androidStandaloneAppClientId: `194202836126-is8mcur724uat774oldlvlugfhstp7il.apps.googleusercontent.com`,
};
import * as FacebookA from 'expo-facebook';
// end workaround

const signInWithApple = (setErrorMessage) => {
    const nonce = Math.random().toString(36).substring(2, 10);

    return Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, nonce)
        .then((hashedNonce) =>
            AppleAuthentication.signInAsync({
                requestedScopes: [
                    AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                    AppleAuthentication.AppleAuthenticationScope.EMAIL
                ],
                nonce: hashedNonce
            })
        )
        .then((appleCredential) => {
            const { identityToken } = appleCredential;
            const provider = new OAuthProvider('apple.com');
            const credential = provider.credential({
                idToken: identityToken,
                rawNonce: nonce
            });
            signInWithCredential(auth, credential).catch(e=>setErrorMessage(e.message));
            // Successful sign in is handled by firebase.auth().onAuthStateChanged
        })
        .catch((error) => {
            // ...
            setErrorMessage(error.message);
        });
};

export default function LoginScreen({ navigation }) {
  // auth.signOut();
  const [errorMessage, setErrorMessage] = useState('');
  const [googleRequest, googleResponse, googlePromptAsync] = Google.useIdTokenAuthRequest({
    clientId: '194202836126-6qa6ihr78fabnd4sh1vbkvphfr45gtmd.apps.googleusercontent.com',
    iosClientId: '194202836126-dlecaf9rdgn8b7gn1mh5ifnvd0512geo.apps.googleusercontent.com',
    androidClientId: '194202836126-is8mcur724uat774oldlvlugfhstp7il.apps.googleusercontent.com',
    androidStandaloneAppClientId: '194202836126-6qa6ihr78fabnd4sh1vbkvphfr45gtmd.apps.googleusercontent.com',
  });
  const [facebookRequest, facebookResponse, facebookPromptAsync] = Facebook.useAuthRequest({
      clientId: '308634341248928',
      responseType: ResponseType.Token,
    });
  const [isAppleLoginAvailable, setIsAppleLoginAvailable] = useState(false);


  useEffect(() => {
      AppleAuthentication.isAvailableAsync().then(result=>{
        // console.log('result:',result);
        setIsAppleLoginAvailable(result);
      });

      // console.log('gResponse:', googleResponse);
      if (googleResponse?.type === 'success') {
        const { id_token } = googleResponse.params;
        const credential = GoogleAuthProvider.credential(id_token);
        // console.log('credential:', credential);
        // Sign in with the credential from the Google user.
        signInWithCredential(auth, credential).catch(error=>console.log('error:', error));
      }
      else if (facebookResponse?.type === 'success') {
        const { access_token } = facebookResponse.params;
        const credential = FacebookAuthProvider.credential(access_token);
        // Sign in with the credential from the Facebook user.
        signInWithCredential(auth, credential).catch(error=>{
          if(error.message.includes('auth/account-exists-with-different-credential')) {
            fetchSignInMethodsForEmail(auth, error.customData.email).then(providers=> {
              // console.log('providers:',providers);
              if(providers.includes("google.com")) {
                setErrorMessage('Account already exists / Please sign in with Google')
              }
            });
          }
        });
      }
    }, [googleResponse,facebookResponse]);


  return (
    <View style={styles.container}>
      <View style={[styles.container]}>
        <Image
          resizeMode={'contain'}
          style={{width:300, height:300, flexShrink:1}}
          source={require('../assets/images/logo.png')}
        />
      </View>
      <Text h2>word / off </Text>
      <View style={styles.container}>
      <Text>Sign in to challenge your friends:</Text>
      <Text></Text>
        <Pressable
          disabled={!googleRequest}
          onPress = {async () => {
            // workaround for android
            if(Platform.OS==='android') {

              const { type, idToken } = await GoogleA.logInAsync(config);
              if (type === 'success') {
                const credential = GoogleAuthProvider.credential(idToken);
                // Sign in with the credential from the Google user.
                signInWithCredential(auth, credential).catch(error=>console.log('error:', error));
              }
            } // end workaround
            else {
              googlePromptAsync();
            }
          }}
        >
          <Image
           style={{width: 191, height: 46}}
           source={require('../assets/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web.png')}
           />
        </Pressable>
        <Pressable
          disabled={!facebookRequest}
          resizeMode={'contain'}
          onPress = { async () => {
            // workaround for android
            if(Platform.OS==='android') {
              await FacebookA.initializeAsync({
                    appId: '308634341248928',
                  });
              const { type, token, expirationDate, permissions, declinedPermissions } =
                await FacebookA.logInWithReadPermissionsAsync({
                  permissions: ['public_profile', 'email'],
                });

              if (type === 'success') {
                const credential = FacebookAuthProvider.credential(token);
                // Sign in with the credential from the Facebook user.
                signInWithCredential(auth, credential).catch(error=>{
                  if(error.message.includes('auth/account-exists-with-different-credential')) {
                    fetchSignInMethodsForEmail(auth, error.customData.email).then(providers=> {
                      // console.log('providers:',providers);
                      if(providers.includes("google.com")) {
                        setErrorMessage('Account already exists / Please sign in with Google')
                      }
                    });
                  }
                });
              }
            } // end workaround
            else {
              facebookPromptAsync().catch(error=>console.log('error:', error));
            }
          }}
        >
          <Image
           style={{width: 191, height: 46}}
           resizeMode={'contain'}
           source={require('../assets/facebook.png')}
           />
        </Pressable>
        {isAppleLoginAvailable && (
                  <View style={{ alignItems: 'center' }}>
                      <AppleAuthentication.AppleAuthenticationButton
                          buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
                          buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                          cornerRadius={25}
                          onPress={()=>signInWithApple(setErrorMessage)}
                          style={{ width: 191, height: 46 }}
                      />
                  </View>
        )}
        <Text>{errorMessage}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});
