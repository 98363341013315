import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';

import { ThemeProvider, Button } from 'react-native-elements';
import { Provider as PaperProvider } from 'react-native-paper';
import Colors from './constants/Colors';

import { useState, useEffect, useRef } from 'react';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';

// Firebase
import { initializeApp } from 'firebase/app';
const firebaseConfig = {
  apiKey: "AIzaSyAHkqD0BSCCkhnTb8PBVyp32DasmpRUV2c",
   authDomain: "felix-1db5a.firebaseapp.com",
   projectId: "felix-1db5a",
   storageBucket: "felix-1db5a.appspot.com",
   messagingSenderId: "194202836126",
   appId: "1:194202836126:web:c8159c2924b4dc23a56f8f",
   measurementId: "G-98DRHQX2M2"
};
initializeApp(firebaseConfig);
// Firebase auth
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, updateDoc } from "firebase/firestore";
const db = getFirestore();

const getElementsTheme = (colorScheme) => {
  return {
    Overlay: {
      overlayStyle: {
        backgroundColor:colorScheme==='light' ? Colors.light.background :
                          Colors.dark.background,
        borderColor:colorScheme==='light' ? Colors.dark.background :
                          Colors.light.background,
        borderWidth:2,
      }
    },
    Button: {
      buttonStyle: {
        backgroundColor: colorScheme==='light' ? Colors.light.background :
                          Colors.dark.background,
      },
      disabledStyle: {
        backgroundColor: colorScheme==='light' ? Colors.light.background :
                          Colors.dark.background,
      },
      titleStyle: {
        color: colorScheme==='light' ? Colors.light.text :
                          Colors.dark.text,
      },
    },
    Icon: {
      color: colorScheme==='light' ? Colors.light.text :
                        Colors.dark.text,
      disabledStyle: {
        backgroundColor: colorScheme==='light' ? Colors.light.background :
                          Colors.dark.background,
      },
    },
    Text: {
      style: {
        color: colorScheme==='light' ? Colors.light.text :
                          Colors.dark.text,
      },
      h1Style: {
        color: colorScheme==='light' ? Colors.light.text :
                          Colors.dark.text,
      },
      h2Style: {
        color: colorScheme==='light' ? Colors.light.text :
                          Colors.dark.text,
      },
      h3Style: {
        color: colorScheme==='light' ? Colors.light.text :
                          Colors.dark.text,
      },
      h4Style: {
        color: colorScheme==='light' ? Colors.light.text :
                          Colors.dark.text,
      },
    },
    ListItem: {
      containerStyle: {
        backgroundColor: colorScheme==='light' ? Colors.light.background :
                          Colors.dark.background,
      },
    },
    ListItemContent: {
      containerStyle: {
        backgroundColor: colorScheme==='light' ? Colors.light.background :
                          Colors.dark.background,
      },
    },
    ListItemTitle: {
      style: {
        color: colorScheme==='light' ? Colors.light.text :
                          Colors.dark.text,
      },
    },
    ListItemSubtitle: {
      style: {
        color: colorScheme==='light' ? Colors.light.text :
                          Colors.dark.text,
      },
    },
    SearchBar: {
      lightTheme: colorScheme==='light',
    },
    Input: {
      color:colorScheme==='light' ? Colors.light.text :
                        Colors.dark.text,
    }
  }
}


Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});



export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();


  if (!isLoadingComplete) {
    return null;
  } else {
    return (
        <ThemeProvider theme={getElementsTheme(colorScheme)}>
        <PaperProvider>
        <SafeAreaProvider>
          <Navigation colorScheme={colorScheme}/>
          <StatusBar />
        </SafeAreaProvider>
        </PaperProvider>
        </ThemeProvider>
      );
  }
}
