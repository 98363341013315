import { useState } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { View } from '../components/Themed';
import { ListItem, Icon, Text } from 'react-native-elements';
import { Surface, ToggleButton } from 'react-native-paper';


export default function HelpScreen({ route, navigation }) {
  const [value, setValue] = useState('player');
    return (
      <ScrollView containerStyle={styles.container}>
        <ListItem
          key={1}
        >
          <ListItem.Title style={{flex:1}}>Guess the word in less tries than your opponent</ListItem.Title>
        </ListItem>
        <ListItem
          key={2}
        >
          <ListItem.Title>
            <Surface key={Math.random().toString()} style={[styles.surface, {backgroundColor:'green'}]}>
            <Text h4>H</Text>
            </Surface>
          </ListItem.Title>
          <ListItem.Subtitle>Letter is in the right place</ListItem.Subtitle>
        </ListItem>
        <ListItem
          key={3}
        >
          <ListItem.Title>
            <Surface key={Math.random().toString()} style={[styles.surface, {backgroundColor:'rgba(255, 255, 0, 0.8)'}]}>
            <Text h4>E</Text>
            </Surface>
          </ListItem.Title>
          <ListItem.Subtitle>Letter is in the word, but in the wrong place</ListItem.Subtitle>
        </ListItem>
        <ListItem
          key={4}
        >
          <ListItem.Title>
            <Surface key={Math.random().toString()} style={[styles.surface, {backgroundColor:'grey'}]}>
            <Text h4>Y</Text>
            </Surface>
          </ListItem.Title>
          <ListItem.Subtitle>Letter is not in the word</ListItem.Subtitle>
        </ListItem>
        <ListItem
          key={5}
        >
          <ListItem.Title>
            <View style={{flexDirection:'row'}}>
              <ToggleButton.Group
                onValueChange={value => setValue(value)}
                value={value}>
                <ToggleButton icon="account" value="player" />
                <ToggleButton icon="account-multiple" value="opponent" />
              </ToggleButton.Group>
            </View>
          </ListItem.Title>
          <ListItem.Subtitle style={{flex:1}}>View your board or your opponent's board</ListItem.Subtitle>
        </ListItem>
        <ListItem
          key={6}
        >
          <Icon name="selection-search" type='material-community' value="player" style={styles.icon}/>
          <ListItem.Title>
            Tap a word to see its definition
          </ListItem.Title>
        </ListItem>

      </ScrollView>
    )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  surface: {
    flex:1,
    flexShrink:1,
    margin:4,
    height:'auto',
    maxHeight:100,
    width:20,
    maxWidth:100,
    aspectRatio:1,
    alignItems: 'center',
    justifyContent:'center',
    elevation: 4,
    borderColor:'grey',
    borderWidth: 2,
    borderRadius: 5,
  },
  icon: {
    width:40,
  },
});
