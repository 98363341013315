import { ActivityIndicator, KeyboardAvoidingView, Platform, StyleSheet, TextInput, FlatList, Alert  } from 'react-native';
import { Avatar, Button, SearchBar, ListItem, Text, Icon, Overlay } from 'react-native-elements';
import { View } from '../components/Themed';
import { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as Contacts from 'expo-contacts';


import {
  getAuth
} from 'firebase/auth';

import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const newGame = httpsCallable(functions, 'newGame');

const addGame = (contact) => {
  // TODO: validate friendEmail

  return newGame({
      player: {displayName: getAuth().currentUser.displayName, email: getAuth().currentUser.email},
      opponent: {displayName: contact.name, email: contact.email.toLowerCase()}
    });
}

function validateEmail(elementValue){
   var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
   return emailPattern.test(elementValue);
 }

export default function AddFriendScreen({ route, navigation }) {
  const [waitingForServer, setWaitingForServer] = useState(false);
  const [query, setQuery] = useState('');
  const [contact, setContact] = useState();
  let data = [{name: null, email:query}];

  const [contacts, setContacts] = useState([]);
  const filteredContacts = contacts.filter(contact=>
    contact.emails && (
    contact.emails?.filter(email=>email.email.toLowerCase().includes(query.toLowerCase())).length > 0 ||
    contact.name?.toLowerCase().includes(query.toLowerCase()) ||
    contact.firstName?.toLowerCase().includes(query.toLowerCase()) ||
    contact.lastName?.toLowerCase().includes(query.toLowerCase()) ||
    contact.nickname?.toLowerCase().includes(query.toLowerCase())
    )
  );
  filteredContacts.forEach(contact =>
    contact.emails.forEach(email=>
      data.push({ name: contact.name, email: email.email, id: email.id})));

  const [overlayVisible, setOverlayVisible] = useState(false);
  const toggleOverlay = () => {
      setOverlayVisible(!overlayVisible);
  };

  useEffect(() => {
      (async () => {
        const { status } = await Contacts.requestPermissionsAsync();
        if (status === 'granted') {
          const { data } = await Contacts.getContactsAsync({
            fields: [Contacts.Fields.Emails],
          });

          if (data.length > 0) {
            const contact = data[0];
            setContacts(data);
          }
        }
      })();
    }, []);

  if(waitingForServer) {
    return (
      <ActivityIndicator/>
    )
  }

  return (
    <KeyboardAvoidingView>
    <Overlay
      isVisible={overlayVisible}
      onBackdropPress={toggleOverlay}
    >
      <Text h4>Add {contact?.name ?? 'friend'}</Text>
        <Text>
          We'll send an email to {contact?.email} to let them know
        </Text>
        <View style={{flexDirection:'row', justifyContent:'center'}}>
        <Button
          icon={
            <Icon
              name="close"
              type="font-awesome"
              size={25}
              iconStyle={{ marginRight: 10 }}
            />
          }
          title="Cancel"
          onPress={toggleOverlay}
        />
        <Button
          icon={
            <Icon
              name="send"
              type="font-awesome"
              size={25}
              iconStyle={{ marginRight: 10 }}
            />
          }
          title="Send"
          onPress={async () => {
            try {
              setWaitingForServer(true);
              const result = await addGame(contact);
              setWaitingForServer(false);
              toggleOverlay();
              navigation.pop();
            } catch(e) {
              console.log('error adding friend', e);
              toggleOverlay();
              setWaitingForServer(false);
            }
          }}
        />
        </View>
    </Overlay>
      <SearchBar
        placeholder={'search contacts...'}
        autoCorrect={false}
        autoCapitalize={"none"}
        value={query}
        onChangeText={setQuery}
      />
      <FlatList
        data = {data}
        keyExtractor = {(item)=>item.id}
        renderItem = {({item}) => (
          <ListItem
            key={item.id}
            bottomDivider
          >
            <ListItem.Content>
            <ListItem.Title>{item.name ?? "Add by email address:"}</ListItem.Title>
            <ListItem.Subtitle right>{item.email || "<enter friend email address above>"}</ListItem.Subtitle>

            </ListItem.Content>
            <Avatar
                rounded={false}
                size={48}
                title= {'+'}
                containerStyle={validateEmail(item.email) ?
                  styles.avatarContainerEnabled : styles.avatarContainerDisabled }
                disabled={!validateEmail(item.email)}
                onPress={ () => {
                  setContact({name:item.name, email:item.email});
                  toggleOverlay();
                }}
              />
          </ListItem>
        )}
      />
    </KeyboardAvoidingView>
  )

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  avatarContainerEnabled: {
    backgroundColor: 'green',
    borderColor:'grey',
    borderWidth:2,
  },
  avatarContainerDisabled: {
    backgroundColor: 'grey',
    borderColor:'grey',
    borderWidth:2,
  },
});
