import dayjs from 'dayjs';
import { View } from 'react-native-animatable';
import * as Notifications from 'expo-notifications';
import {
  ActivityIndicator,
  Alert,
  Animated,
  AppState,
  FlatList,
  Image,
  Linking,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  TextInput,
  Pressable,
  Keyboard,
  Platform,
} from 'react-native';
import * as Device from 'expo-device';

import { Fragment, useState, useEffect, useRef } from 'react';
import { RootTabScreenProps } from '../types';

// Firebase
import { initializeApp } from 'firebase/app';
const firebaseConfig = {
  apiKey: "AIzaSyAHkqD0BSCCkhnTb8PBVyp32DasmpRUV2c",
   authDomain: "felix-1db5a.firebaseapp.com",
   projectId: "felix-1db5a",
   storageBucket: "felix-1db5a.appspot.com",
   messagingSenderId: "194202836126",
   appId: "1:194202836126:web:c8159c2924b4dc23a56f8f",
   measurementId: "G-98DRHQX2M2"
};
initializeApp(firebaseConfig);
// Firebase auth
import { getAuth } from 'firebase/auth';
// Firebase firestore
import { getFirestore, collection, query, where, onSnapshot, doc, getDoc, updateDoc } from "firebase/firestore";
const db = getFirestore();
// Firebase functions
import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();
const newGame = httpsCallable(functions, 'newGame');
const nudge = httpsCallable(functions, 'nudge');

import {
  ButtonGroup,
  Divider,
  Icon,
  Button,
  Text,
  ListItem,
  Badge,
  ListItemProps,
  Switch,
  colors,
  Slider,
  Overlay,
} from 'react-native-elements';

import { Avatar, Chip, Surface, DataTable, ToggleButton } from 'react-native-paper';

const wordList = require('word-list-json');

import FocusLock from 'react-focus-lock';
import { FontAwesome } from '@expo/vector-icons';
import useColorScheme from '../hooks/useColorScheme';
import Colors from '../constants/Colors';

const sendGuess = async (match, player, game, guess) => {
  const gameRef = doc(db, `matches/${match.id}/games`, game.id);
  let update = {};
  let updatedPlayer = {};
  if(player.email == game.playerOne.email) {
    updatedPlayer = { ...game.playerOne };
    const guessNumber = updatedPlayer.guesses.findIndex(g=>g==null);
    if(guessNumber!=-1) {
      updatedPlayer.guesses[guessNumber] = guess;
    }
    update = {playerOne:updatedPlayer};
  } else if(player.email == game.playerTwo.email) {
    updatedPlayer = { ...game.playerTwo};
    const guessNumber = updatedPlayer.guesses.findIndex(g=>g==null);
    if(guessNumber!=-1) {
      updatedPlayer.guesses[guessNumber] = guess;
    }
    update = {playerTwo:updatedPlayer};
  }
  // console.log('update:', update);
  return updateDoc(gameRef, update);
}

const sendChallenge = async (match, player, game, challenge) => {
  const gameRef = doc(db, `matches/${match.id}/games`, game.id);
  let update = {};
  let updatedPlayer = {};
  if(player.email == game.playerOne.email) {
    updatedPlayer = {
      ...game.playerOne,
      challengeWord: challenge,
      displayName: game.playerOne.displayName  ?? (getAuth().currentUser.displayName ?? getAuth().currentUser.email),
      photoUrl: getAuth().currentUser.photoURL ?? null,
    };
    update = {playerOne:updatedPlayer};
  } else if(player.email == game.playerTwo.email) {
    updatedPlayer = {
      ...game.playerTwo,
      challengeWord: challenge,
      displayName: game.playerTwo.displayName ?? (getAuth().currentUser.displayName ?? getAuth().currentUser.email),
      photoUrl: getAuth().currentUser.photoURL ?? null,
    };
    update = {playerTwo:updatedPlayer};
  }
  return updateDoc(gameRef, update);
}

const CustomKeyboard = (props) => {
  let keyColors = new Map();

  const Key = (props) => {
    return (
      <Button
        title={props.letter}
        containerStyle={[styles.surfaceKey, {alignContent:'stretch',alignItems:'stretch'}]}
        buttonStyle={[
          {borderRadius:0, width:'100%', height:'100%', backgroundColor:keyColors?.get(props.letter) || null}]}
        disabledStyle={[
          {borderRadius:0, width:'100%', height:'100%', backgroundColor:keyColors?.get(props.letter) || null}]}
        onPress={()=>props.onPress(props.letter)}
        disabled={props.disabled}
      />
    )
  }

  props.guesses?.filter(guess=>guess!=null).forEach(guess => {
    guess.toUpperCase().split('').forEach( (letter, index) => {
      if(letter === props.challengeWord.charAt(index)) {
        keyColors.set(letter,'green');
      }
      else if (props.challengeWord.includes(letter)) {
        if(keyColors.get(letter)!='green') {
            keyColors.set(letter,'rgba(255, 255, 0, 0.8)');
        }
      }
      else if(!props.challengeWord.includes(letter)) {
        if(!keyColors.has(letter)) {
          keyColors.set(letter,'grey');
        }
      }
    });
  });

  const oldWord = props.guess || '';
  const letterEntryDisabled = (oldWord.length === props.wordLength);
  return (
    <View style={styles.container}>
      <View style={styles.keyboardRow}>
        <Key
          letter='Q'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=> {
            props.onLetterPressed(oldWord+letter);
          }}
        />
        <Key
          letter='W'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='E'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='R'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='T'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='Y'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='U'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='I'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='O'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='P'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
      </View>

      <View style={styles.keyboardRow} >
        <Key
          letter='A'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='S'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='D'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='F'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='G'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='H'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='J'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='K'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='L'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
      </View>

      <View style={styles.keyboardRow} >
        <Button
          buttonStyle={{width:'100%', height:'100%'}}
          disabledStyle={{width:'100%', height:'100%'}}
          containerStyle={styles.surfaceKey}
          icon=<Icon
            type='material'
            name='send'
            disabled={props.disabled || oldWord.length != props.wordLength}
            />
          disabled={props.disabled || oldWord.length != props.wordLength}
          onPress={props.onSubmit}
        />
        <Key
          letter='Z'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='X'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='C'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='V'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='B'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='N'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Key
          letter='M'
          disabled={letterEntryDisabled || props.disabled}
          onPress={(letter)=>props.onLetterPressed(oldWord+letter)}
        />
        <Button
          buttonStyle={{width:'100%', height:'100%'}}
          disabledStyle={{width:'100%', height:'100%'}}
          containerStyle={styles.surfaceKey}
          icon=<Icon type='material' name='backspace'/>
          onPress={props.onPressBackspace}
          disabled={props.guess?.length==0}
        />
      </View>
    </View>
  )
}

const WordRow = (props) => {
  if(!props.word) {
    return null;
  }
  const letters = props.word.split('');

  let letterCount = new Map();
  let exactMatchCount = new Map();
  let yellowCount = new Map();

  props.challengeWord.split('').forEach( (c,i) => {
    letterCount.set(c, (letterCount.get(c) ?? 0) +1);
  });

  letters.forEach((c,i)=> {
    if(!exactMatchCount.has(c)) {
      exactMatchCount.set(c,0);
    }
    if(c === props.challengeWord.charAt(i)) {
      if(exactMatchCount.has(c)) {
        exactMatchCount.set(c, (exactMatchCount.get(c) ?? 0) +1);
      }
    }
  });

  let matchStyles = [];
  letters.forEach((c,i) => {
    if(c == props.challengeWord.charAt(i)) {
      matchStyles[i] = [styles.surface, {backgroundColor:'green'}];
    }
    else if(props.challengeWord.includes(c) && letterCount.get(c)>exactMatchCount.get(c)) {
      if((yellowCount.get(c) ?? 0) < letterCount.get(c) - exactMatchCount.get(c)) {
        matchStyles[i] = [styles.surface, {backgroundColor:'rgba(255, 255, 0, 0.8)'}];
        yellowCount.set(c,(yellowCount.get(c) ?? 0) +1);
      }
      else {
        matchStyles[i] = [styles.surface, {backgroundColor:'grey'}];
      }
    }
    else {
      matchStyles[i] = [styles.surface, {backgroundColor:'grey'}];
    }
  });
  return (

    <View animation={props.fadeIn ? "fadeIn" : null} duration={2000} style={[styles.wordContainer]} >
      {letters.map( (c,i) => (
        <Pressable key={Math.random()} style={{flex:1, flexDirection:'row'}} onPress={()=>props.onPress(props.word)}>
            <Surface key={Math.random()} style={[matchStyles[i]]}>
              <Text key={Math.random()} h4>{c.toUpperCase()}</Text>
            </Surface>
        </Pressable>
          ))}
    </View>

  )
}

const GuessRow = (props) => {
  return (
    <View {...props} style={[styles.wordContainer]}>
    {Array(props.wordLength).fill(null).map( (c,i) => (
          <Surface key={Math.random()} style={[styles.surface]}><
          Text key={Math.random()} h4>{props.guessWord?.charAt(i) ?? ' '}</Text>
          </Surface>
        ))}
    </View>
  )
}

const EmptyRow = (props) => {
  return (
    <View style={[styles.wordContainer]}>
      { Array(props.wordLength).fill(null).map(()=><Surface key={Math.random()} style={styles.surface}/>) }
    </View>
  )
}

const PlayerBoard = (props) => {
  const viewRef = useRef();

  const { game,
          guess,
          player,
          opponent,
          disabled,
          onChangeGuess,
          onPressBackspace,
          deviceType,
        } = props;

  let numEmptyRows = game.maxTries - (player.guesses?.filter(g=>g!=null).length) - 1;
  numEmptyRows = numEmptyRows > 0 ? numEmptyRows : 0;

  // console.log("device type:", deviceType);
  // console.log('Device.DeviceType.DESKTOP:', Device.DeviceType.DESKTOP);
  return (
    <View ref={viewRef} style={[styles.container, {flex: 1, width:'100%'}]}>

      {(false || deviceType==Device.DeviceType.DESKTOP) ? (
        <View style={[{flexShrink:1}]}>
          <FocusLock>
          <TextInput
                style={{height:0,width:0}}
                autoFocus={true}
                showSoftInputOnFocus={false}
                blurOnSubmit={false}
                onChangeText={t=> {
                  if(t.length <= game.wordLength && /^$|^[a-zA-Z]+$/.test(t)) {
                    props.onChangeText(t.toUpperCase());
                  }
                }}
                onSubmitEditing={t => {
                  if(!wordList.includes(guess.toLowerCase())) {
                    viewRef?.current?.bounce(600);
                  }
                  else {
                    props.onSubmitGuess();
                  }
                }}
                value={props.guess}
            />
          </FocusLock>
        </View>
        ) : null
      }

      <View style={props.containerStyle}>
        {player.guesses?.filter(g=>g!=null).map((g,i) =>
            <WordRow
              key={i}
              word={g}
              fadeIn = {player.guesses?.filter(g=>g!=null).length == i+1}
              challengeWord={opponent.challengeWord}
              onPress={()=>props.onPress(g)}
            />
        )}


        {player.guesses?.filter(g=>g!=null).length < game.maxTries ?
          <GuessRow wordLength={game.wordLength} guessWord={guess}/> : null }

        {Array.apply(null, Array(numEmptyRows))?.map(()=><EmptyRow key={Math.random()} wordLength={game.wordLength}/>)}
      </View>
    </View>
  )
}

const OpponentBoard = (props) => {
  const { game,
          guess,
          player,
          opponent,
          disabled,
          onChangeGuess,
          onPressBackspace
        } = props;

  return (
    <View style={[styles.container, {flex: 1, width:'100%'}]}>
      <View style={styles.boardContainer} animation='fadeIn' duration={2000}>
        {opponent.guesses?.filter(g=> g!=null).map(g =>
          <WordRow
            key={Math.random()}
            word={g}
            challengeWord={player.challengeWord}
            onPress={()=>props.onPress(g)}
          />)}
        {opponent.guesses?.filter(g => g==null).map(()=>
          <EmptyRow key={Math.random()} wordLength={game.wordLength}/>)}
      </View>
    </View>
  )
}

const Summary = (props) => {
  const {
    match,
    game,
    guess,
    player,
    opponent,
    disabled,
    onChangeGuess,
    onPressBackspace,
    onNewGame,
    setDefineWord,
    setShowDefinition,
    showDefinition
  } = props;
  const [waitingForServer,setWaitingForServer] = useState(false);

  return (
    <View style={[styles.container, {flex: 1, width:'100%'}]} animation="fadeIn" duration={2000}>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>Player</DataTable.Title>
          <DataTable.Title>Word</DataTable.Title>
          <DataTable.Title numeric>Guesses</DataTable.Title>
        </DataTable.Header>
        <DataTable.Row
          style={{backgroundColor: (game.winner === player.email) ? 'green' : null}}
        >
          <DataTable.Cell>{player.displayName ?? player.email}</DataTable.Cell>
          <DataTable.Cell onPress={!player.finished ? null : ()=>{setDefineWord(opponent.challengeWord);setShowDefinition(!showDefinition);}}>{player.finished ? opponent.challengeWord : '*****'}{player.finished && <Icon name='help-box' type='material-community'/>}</DataTable.Cell>
          <DataTable.Cell numeric>{player.finished ? (player.guessedIn ?? '-') : 'STILL THINKING'}</DataTable.Cell>
        </DataTable.Row>
        <DataTable.Row
          style={{backgroundColor: (game.winner === opponent.email) ? 'green' : null }}
        >
          <DataTable.Cell>{opponent.displayName ?? opponent.email}</DataTable.Cell>
          <DataTable.Cell onPress={()=>{setDefineWord(player.challengeWord);setShowDefinition(!showDefinition)}}>{player.challengeWord}<Icon name='help-box' type='material-community'/></DataTable.Cell>
          <DataTable.Cell numeric>{opponent.finished ? (opponent.guessedIn ?? '-') :'STILL THINKING'}</DataTable.Cell>
        </DataTable.Row>
      </DataTable>
      <View style={{flex: 1, flexDirection:'row', width:'100%', justifyContent:'center', alignItems:'center', paddingTop:15}}>
        {!game.closed ? (
          <View style={{flexDirection:'row', flex:1, justifyContent:'center', alignItems: 'center'}}>

          { !opponent.finished &&
            <Button
              title={`Nudge`}
              icon={{name: 'kabaddi', type:'material-community'}}
              iconRight
              loading={waitingForServer}
              disabled={waitingForServer || dayjs.unix(game.nudge?.seconds).isAfter(dayjs().startOf('day'))}
              buttonStyle={{backgroundColor:'green', borderRadius:10}}
              disabledStyle={{backgroundColor:'grey'}}
              onPress = { async ()=> {
                setWaitingForServer(true);
                try {
                  const result = await nudge({match, game});
                } catch(e) {
                  console.log(e.message);
                } finally {
                  setWaitingForServer(false);
                }
              }
              }
            />
          }
          </View>
          )
        :
          <Button
            buttonStyle={{backgroundColor:'green'}}
            loading={props.disabled}
            disabled={props.disabled}
            disabledStyle={{backgroundColor:'grey'}}
            title='play again'
            onPress={onNewGame}
            />
        }
      </View>
    </View>
  )
}

const ChallengeEntry = (props) => {
  const {
    disabled,
    onChangeChallenge,
    onSubmitChallenge,
    player,
    opponent,
    guess,
    challenge,
    game,
    guessRowRef
  } = props;

  return (
    <View style={[styles.container, {flex: 1, width:'100%', justifyContent:'center'}]}>
      <Text h4 h4Style={{marginBottom: 20}}>Pick a word for your opponent</Text>
      <GuessRow
        wordLength={game.wordLength}
        guessWord={challenge}
      />
    </View>
  )
}

const Game = (props) => {
  // console.log('game props:', props);

  const {
    match,
    game,
    guess,
    player,
    opponent,
    disabled,
    onChangeGuess,
    onPressBackspace,
    challenge,
    onChangeChallenge,
    onSubmitChallenge,
  } = props;

  const viewRef = useRef();
  const [value, setValue] = useState('player');
  const [notifications, setNotifications] = useState(false);
  const [defineWord, setDefineWord] = useState();
  const [definition, setDefinition] = useState();
  const [showDefinition, setShowDefinition] = useState(false);
  const [gettingDefinition, setGettingDefinition] = useState(false);
  const [waitingForServer, setWaitingForServer] = useState(false);

  const discoverPermissionsAsync = async () => {
    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        setNotifications(false);
      } else {
        setNotifications(true);
      }
    } else {
      // console.log('Not on physical device');
      setNotifications(false);
    }
  }
  useEffect(() => discoverPermissionsAsync(), []);

  useEffect(() => {
    AppState.addEventListener('change', discoverPermissionsAsync);

    return () => {
      AppState.removeEventListener('change', discoverPermissionsAsync);
    };
  }, []);

  const retrieveDefinitionAsync = async () => {
    setGettingDefinition(true);
    fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${defineWord}`,{})
      .then(response=>response.json())
      .then(data=>setDefinition(data?.[0]))
      .finally(()=>setGettingDefinition(false));
  }
  useEffect(()=> retrieveDefinitionAsync(), [defineWord]);


  if(!(game && player && opponent)) {
    return (
      <View>
        <ActivityIndicator/>
      </View>
    )
  }

  // Player needs to enter challenge word
  if(!player.challengeWord) {
    return (
      <View style = {[styles.container, {flex: 1, width:'100%', justifyContent:'flex-end'}]} ref={viewRef}>
        <View style= {[styles.container, {justifyContent:'center', alignContent:'center', alignItems:'center'}]}>
          <ChallengeEntry {...props}/>
        </View>
        <View style={styles.keyboardContainer}>
          <CustomKeyboard
            disabled={disabled}
            onSubmit= {()=> {
              if(!wordList.includes(challenge.toLowerCase())) {
                viewRef?.current?.bounce(600);
              }
              else {
                onSubmitChallenge();
              }
            }}
            guesses={player.guesses}
            challengeWord={opponent.challengeWord}
            wordLength={game.wordLength}
            guess={challenge}
            onLetterPressed={(challenge)=>onChangeChallenge(challenge)}
            onPressBackspace={()=>onChangeChallenge(challenge.substr(0,challenge.length-1))}
          />
        </View>
      </View>
    )
  }


  // Waiting on opponent's challenge word
  if(!opponent.challengeWord) {
    // return (
    //   <View style = {[styles.container,  {justifyContent:'flex-start', alignItems:'stretch'}]}>
    //       <ListItem key={Math.random()}>
    //         <ListItem.Title>{`Waiting for ${opponent.displayName ?? opponent.email}'s word`}</ListItem.Title>
    //       </ListItem>
    //
    //                 <ListItem key={Math.random()}>
    //                   <ListItem.Content>
    //                     <ListItem.Title style={{flex:1}}>Notifications</ListItem.Title>
    //                     {Platform.OS === 'web' && <ListItem.Subtitle>      (iOS/Android only)</ListItem.Subtitle> }
    //                   </ListItem.Content>
    //                     <Switch
    //                       disabled={Platform.OS ==='web'}
    //                       value={notifications}
    //                       color={'green'}
    //                       onValueChange={()=> {
    //                         Alert.alert(
    //                           'Contacts',
    //                           'Change permissions in your app settings',
    //                           [
    //                             {
    //                               text: "Cancel",
    //                               onPress: () => console.log("Cancel Pressed"),
    //                               style: "cancel"
    //                             },
    //                             { text: "OK",
    //                               onPress: ()=>Linking.openURL('app-settings:')
    //                             }
    //                           ]
    //                         )
    //                         }
    //                       }
    //                     />
    //                 </ListItem>
    //                 <ListItem key={Math.random()}>
    //                   <ListItem.Content>
    //                   <ListItem.Title style={{flex:1}}>Nudge {opponent.displayName ?? opponent.email}</ListItem.Title>
    //                   {dayjs.unix(game.nudge?.seconds)?.isAfter(dayjs().startOf('day')) && <ListItem.Subtitle>      (once a day)</ListItem.Subtitle>}
    //                   </ListItem.Content>
    //                   <Button
    //                     title="Send"
    //                     loading={waitingForServer}
    //                     disabled={waitingForServer || dayjs.unix(game.nudge?.seconds)?.isAfter(dayjs().startOf('day'))}
    //                     buttonStyle={{backgroundColor:'green'}}
    //                     disabledStyle={{backgroundColor:'grey'}}
    //                     onPress = { async ()=> {
    //                       setWaitingForServer(true);
    //                       try {
    //                         const result = await nudge({match, game});
    //                       } catch(e) {
    //                         console.log(e.message);
    //                       } finally {
    //                         setWaitingForServer(false);
    //                       }
    //                     }
    //                   }
    //                   />
    //                 </ListItem>
    //               {Platform.OS==='web' &&
    //               <View style={{marginBottom:50, alignItems:'center'}}>
    //                 <Text style={{marginBottom:25}}>Get the mobile app:</Text>
    //                   <Pressable onPress={()=>Linking.openURL('https://apps.apple.com/us/app/word-off/id1610878606')}>
    //                     <Image
    //                      style={{width: 191, height: 46}}
    //                      resizeMode={'contain'}
    //                      source={require('../assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_0929170.svg')}
    //                      />
    //                   </Pressable>
    //                   <Pressable onPress={()=>Linking.openURL('https://play.google.com/store/apps/details?id=com.takwai.wordoff')}>
    //                      <Image
    //                       style={{width: 191, height: 64}}
    //                       resizeMode={'contain'}
    //                       source={require('../assets/images/google-play-badge.png')}
    //                       />
    //                   </Pressable>
    //               </View>
    //               }
    //               {Platform.OS!=='web' &&
    //               <View style={{marginBottom:50, alignItems:'center'}}>
    //                 <Text style={{marginBottom:25}}>Feedback, suggestions, bugs?</Text>
    //                   <Pressable onPress={()=>Linking.openURL('mailto:admin@wordoff.app')}>
    //                     <Text style={{marginBottom:25, color:'blue'}}>admin@wordoff.app</Text>
    //                   </Pressable>
    //
    //               </View>
    //               }
    //   </View>
    // )





    return (
      <View style = {[styles.container, {justifyContent:'space-between', alignItems:'stretch'}]}>
        <View>
          <ListItem key={Math.random()}>
            <ListItem.Title>{`Waiting for ${opponent.displayName ?? opponent.email}'s word`}</ListItem.Title>
          </ListItem>

          <ListItem key={Math.random()}>
            <ListItem.Content>
              <ListItem.Title style={{flex:1}}>Notifications</ListItem.Title>
              {Platform.OS === 'web' && <ListItem.Subtitle>      (iOS/Android only)</ListItem.Subtitle> }
            </ListItem.Content>
              <Switch
                disabled={Platform.OS ==='web'}
                value={notifications}
                color={'green'}
                onValueChange={()=> {
                  Alert.alert(
                    'Notifications',
                    'Change permissions in your app settings',
                    [
                      {
                        text: "Cancel",
                        onPress: () => console.log("Cancel Pressed"),
                        style: "cancel"
                      },
                      { text: "OK",
                        onPress: ()=>Linking.openURL('app-settings:')
                      }
                    ]
                  )
                  }
                }
              />
          </ListItem>
          <ListItem key={Math.random()}>
            <ListItem.Content>
            <ListItem.Title style={{flex:1}}>Nudge {opponent.displayName ?? opponent.email}</ListItem.Title>
            {dayjs.unix(game.nudge?.seconds)?.isAfter(dayjs().startOf('day')) && <ListItem.Subtitle>      (once a day)</ListItem.Subtitle>}
            </ListItem.Content>
            <Button
              title="Send"
              loading={waitingForServer}
              disabled={waitingForServer || dayjs.unix(game.nudge?.seconds)?.isAfter(dayjs().startOf('day'))}
              buttonStyle={{backgroundColor:'green'}}
              disabledStyle={{backgroundColor:'grey'}}
              onPress = { async ()=> {
                setWaitingForServer(true);
                try {
                  const result = await nudge({match, game});
                } catch(e) {
                  console.log(e.message);
                } finally {
                  setWaitingForServer(false);
                }
              }
            }
            />
          </ListItem>
        </View>
        {Platform.OS==='web' &&
        <View style={{marginBottom:50, alignItems:'center'}}>
          <Text style={{marginBottom:25}}>Get the mobile app:</Text>
            <Pressable onPress={()=>Linking.openURL('https://apps.apple.com/us/app/word-off/id1610878606')}>
              <Image
               style={{width: 191, height: 46}}
               resizeMode={'contain'}
               source={require('../assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_0929170.svg')}
               />
            </Pressable>
            <Pressable onPress={()=>Linking.openURL('https://play.google.com/store/apps/details?id=com.takwai.wordoff')}>
               <Image
                style={{width: 191, height: 64}}
                resizeMode={'contain'}
                source={require('../assets/images/google-play-badge.png')}
                />
            </Pressable>
        </View>
        }
        {Platform.OS!=='web' &&
        <View style={{marginBottom:50, alignItems:'center'}}>
          <Text style={{marginBottom:25}}>Feedback, suggestions, bugs?</Text>
            <Pressable onPress={()=>Linking.openURL('mailto:admin@wordoff.app')}>
              <Text style={{marginBottom:25, color:'blue'}}>admin@wordoff.app</Text>
            </Pressable>

        </View>
        }
      </View>
    )
  }


  return (
    <View ref={viewRef} style={styles.container}>
      <Overlay
        isVisible={showDefinition}
        onBackdropPress={()=>setShowDefinition(false)}
        overlayStyle={{maxHeight:'60%', minWidth:250}}
      >
        {gettingDefinition ? <ActivityIndicator/> :
          <ScrollView
            style={{flex: 1}}
            contentContainerStyle={{flexGrow:1}}
            >
          <View style={{flexDirection:'row', justifyContent:'space-between'}}>
            <Text h2>{defineWord}</Text>
            <Icon
              type='material-community'
              name='close'
              onPress={()=>setShowDefinition(false)}
            />
          </View>

          { !definition &&
            <Fragment>
            <Text></Text>
            <Text>Sorry, couldn't find a definition for {defineWord}.</Text>
            <Text></Text>
            <Text>Not sure what {defineWord} is or what it means to {defineWord}, but {defineWord} is definitely maybe a word. </Text>
            </Fragment>
          }
          <Text>{definition?.phonetics?.find(p=>'text' in p)?.text}</Text>
          { definition?.meanings?.map(meaning=>
                <Fragment key={Math.random()}>
                <Text key={Math.random()} h4>{meaning.partOfSpeech}</Text>
                {meaning.definitions.map(definition=>
                  <Text key={Math.random()}>-{definition.definition}</Text>)}
                </Fragment>
              )
          }
          </ScrollView>
        }
      </Overlay>

      { value==='player' ?
        <PlayerBoard {...props}
          onPress={(word) => {
            setDefineWord(word);
            setShowDefinition(!showDefinition);
          }}
          containerStyle={styles.boardContainer}/>
        :
        <OpponentBoard {...props}
          onPress={(word) => {
            setDefineWord(word);
            setShowDefinition(!showDefinition);
          }}
        />
      }

      <View style={styles.keyboardContainer}>

        <View style={{flexDirection:'row', width: '100%', justifyContent:'space-around'}}>
          <View style={{flexDirection:'row'}}>
            <View>
              <Chip
                avatar={player.photoUrl ? <Avatar.Image size={24} source={{uri:player.photoUrl}}/> :
                  <Avatar.Text size={24} label={player.displayName ? player.displayName.charAt(0).toUpperCase() : player.email.charAt(0).toUpperCase()}/>}
                textStyle={{width:72, textAlign:'center'}}
                ellipsizeMode='tail'
                onPress={()=>setValue('player')} mode={value==='player' ? 'outlined' : 'flat'}>
                {player.displayName ?? player.email}
              </Chip>
              <Badge
                status={player.guesses.indexOf(opponent.challengeWord)!=-1 ? 'success' : (player.guesses.filter(g=>g!=null).length < 6 ? 'warning' : 'error')}
                value={player.guesses.filter(g=>g!=null).length}
                containerStyle={{ position: 'absolute', top: -5, right:0}}
              />
            </View>
            <View>
              <Chip
                avatar={opponent.photoUrl ? <Avatar.Image size={24} source={{uri:opponent.photoUrl}}/> :
                  <Avatar.Text size={24} label={opponent.displayName ? opponent.displayName.charAt(0).toUpperCase() : opponent.email.charAt(0).toUpperCase()}/>}
                textStyle={{width:72, textAlign:'center'}}
                ellipsizeMode='tail'
                onPress={()=>setValue('opponent')} mode={value==='opponent' ? 'outlined' : 'flat'}>
                {opponent.displayName ?? opponent.email}
              </Chip>
              <Badge
                status={opponent.guesses.indexOf(player.challengeWord)!=-1 ? 'success' : (opponent.guesses.filter(g=>g!=null).length < 6 ? 'warning' : 'error')}
                value={opponent.guesses.filter(g=>g!=null).length}
                containerStyle={{ position: 'absolute', top: -5, right:0}}
              />
            </View>
          </View>
        </View>

      {!player.finished && value=='player' ?
        <CustomKeyboard
          disabled={props.disabled || player.finished}
          onSubmit={()=> {
            if(!wordList.includes(guess.toLowerCase())) {
              viewRef?.current?.bounce(600);
            }
            else {
              props.onSubmitGuess();
            }
          }}
          guesses={player.guesses}
          challengeWord={opponent.challengeWord}
          wordLength={game.wordLength}
          guess={guess}
          onLetterPressed={(guess)=>{
            onChangeGuess(guess);
          }}
          onPressBackspace={()=>onChangeGuess(guess.substr(0,guess.length-1))}
        /> :
        <Summary {...props} setDefineWord={setDefineWord} setShowDefinition={setShowDefinition} showDefinition={showDefinition}/>
      }
      </View>
    </View>
  )
}


export default function GameScreen({ route, navigation }) {
  const props = route.params;
  const [match, setMatch] = useState(props.match);
  const [game, setGame] = useState(props.game);
  const [challenge, setChallenge] = useState(null);
  const [guess, setGuess] = useState('');
  const [player, setPlayer] = useState();
  const [opponent, setOpponent] = useState();
  const [waitingForServer, setWaitingForServer] = useState(false);
  const [deviceType, setDeviceType] = useState();

  const colorScheme = useColorScheme();

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View style={{flexDirection:'row'}}>
          <Pressable
            onPress={() => navigation.navigate('Score', {match: {...match}})}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
            })}>
            <Icon
              type='material-community'
              name='counter'
              color= {colorScheme==='light' ? Colors.light.text :
                                Colors.dark.text}
              style={{ marginRight: 10 }}
            />
          </Pressable>
          <Pressable
            onPress={() => navigation.navigate('Settings')}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
            })}>
            <Icon
              type='material-community'
              name='cog'
              color= {colorScheme==='light' ? Colors.light.text :
                                Colors.dark.text}
              style={{ marginRight: 10 }}
            />
          </Pressable>
        </View>
      )
    })

    const getDeviceType = async () => {
        setDeviceType(await Device.getDeviceTypeAsync());
    }
    getDeviceType();

    // console.log('path:', `/matches/${match.id}/games/${game.id}`);
    const gameRef = doc(db, `/matches/${match.id}/games/${game.id}`);
    const unsubscribe = onSnapshot(gameRef, (doc) => {
      // console.log("Current data: ", doc.data());
      if(!doc.data()) {
        return;
      }

      setGame({
        ...doc.data(),
        id: doc.id,
      });

      if(doc.data().playerOne.email == getAuth().currentUser.email) {
        // Player is p1
        setPlayer(doc.data().playerOne);
        setOpponent(doc.data().playerTwo);
      } else {
        // Player is p2
        setPlayer(doc.data().playerTwo);
        setOpponent(doc.data().playerOne);
      }
    });
    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe()
  }, []);

  // console.log('match:', match);
  return (
    <Game
      deviceType={deviceType}
      disabled={waitingForServer}
      match={match}
      game={game}
      player={player}
      opponent={opponent}
      challenge={challenge}
      onChangeText={t=>setGuess(t)}
      onChangeChallenge={challenge=>setChallenge(challenge)}
      onSubmitChallenge={async ()=> {
        setWaitingForServer(true);
        try {
          await sendChallenge(match, player, game, challenge);
          setWaitingForServer(false);
        } catch(e) {
          // console.log('error submitting:', e);
          setWaitingForServer(false);
        }
      }}
      guess={guess}
      onChangeGuess={guess=>{setGuess(guess);}}
      onSubmitGuess={async ()=> {
        setWaitingForServer(true);
        const temp = guess;
        try {
          setGuess('');
          await sendGuess(match, player, game, temp);
          if(guess === opponent.challengeWord) {
            setPlayer({...player, finished:true});
          }
          setWaitingForServer(false);
        } catch(e) {
          // console.log('error submitting:', e);
          setGuess(temp);
          setWaitingForServer(false);
        }
      }}
      onNewGame={async ()=> {
        //unsubscribe?
        setWaitingForServer(true);
        try {
          const result = await newGame({
                    player: {
                      email:player.email,
                      displayName: player.displayName,
                    },
                    opponent:{
                      email:opponent.email,
                      displayName: opponent.displayName,
                    },
                  });
          // console.log('newGame result:', result);
          // console.log('match is:', match);
          setWaitingForServer(false);
          navigation.push('Game', {
                match: match,
                game: result.data,
              });
        } catch(e) {
          setWaitingForServer(false);
        }
      }}
    />
  )
}



const styles = StyleSheet.create({
  container: {
    flex:1,
    flexGrow:1,
    flexShrink:1,
    alignItems: 'center',
    justifyContent: 'space-around',
    width:'100%',
  },
  wordContainer: {
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
    width:'auto',
    height:'15%',
    maxHeight:100,
    aspectRatio:5,
  },
  surface: {
    flex:1,
    flexShrink:1,
    margin:4,
    height:'auto',
    maxHeight:100,
    width:20,
    maxWidth:100,
    aspectRatio:1,
    alignItems: 'center',
    justifyContent:'center',
    elevation: 4,
    borderColor:'grey',
    borderWidth: 2,
    borderRadius: 5,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '75%',
  },
  keyboardRow: {
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
    width:'100%',
  },
  surfaceKey: {
    flex:1,
    margin:2,
    width: '10%',
    height:'auto',
    maxHeight: 55,
    borderColor:'grey',
    borderWidth: 2,
    borderRadius: 5,
  },
  boardContainer: {
    flexGrow:1,
    flexShrink:1,
    width:'85%',
    maxWidth:800,
    justifyContent:'center',
    alignItems:'center',
  },
  keyboardContainer: {
    flexGrow:0,
    flexShrink:1,
    width:'95%',
    maxWidth:800,
    height:240,
    justifyContent:'flex-end',
    alignItems:'center',
    marginBottom: 20,
  },
});
